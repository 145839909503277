.ItemCategory {
  margin-bottom: 0.5rem;

  .divider {
    background-color: #f4f4f4;
    border: 0.55px solid #c8c7cc;
    border-radius: .25rem;
    color: #000;
  }

  h2 {
    font-size: 1.1rem;
    padding: 0 1rem;
    margin: 1rem 0;
  }

  .cart-qty {
    float: right;
    margin-left: 1rem;
  }
}