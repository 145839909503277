.Home {
  .logo-wrapper {
    background: #444;
    padding: 1em;
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  .content-wrapper {
    padding: 1em;

    button {
      font-size: 2rem;
      height: 2.3em;
      margin: 10px 0;
      text-transform: uppercase;

      .fas {
        font-size: 80%;
        padding-right: 0.5rem;
      }
    }

    p {
      padding: 1em;
    }
  }
}
