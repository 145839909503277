.CartProduct {
  align-items: center;
  border: 0;
  border-bottom: 0.55px solid #c8c7cc;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0;
  min-height: 4.4rem;
  overflow: hidden;
  padding: 0 0 0 16px;
  position: relative;
  text-align: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  > .item-block:last-child {
    border-bottom: none;
  }

  .item-inner {
    flex: 1;
    margin-left: 24px;

    > .icon {
      min-height: 2.8rem;
      font-size: 2.8rem;
      line-height: 1;

      &:first-child {
        text-align: center;
        min-width: 24px;
      }
    }
  }

  .thumbnail {
    margin: 8px 16px 8px 0;
  }
}