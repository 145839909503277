.MainNavbar {
  min-height: 55px;

  &.navbar {
    background-color: #444444;
  }

  .backButton {
    display: block;
    padding: .5rem 0;
  }

  .brand-centered {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 1;
  }

  .navbar-brand {
    img {
      max-height: 45px;
    }
  }
}
