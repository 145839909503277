/* Generate simple margin and padding classes for us to use in the form of .m-t-1, .p-r-1, etc */
$boxParts: padding margin;
$sides: top right bottom left;
$maxUnit: 9;
$unit: rem;

@each $boxPart in $boxParts {
  @each $side in $sides {
    @for $i from 0 through $maxUnit {
      .#{str-slice($boxPart, 0, 1)}-#{str-slice($side, 0, 1)}-#{$i} {
        #{$boxPart}-#{$side}: #{$i}#{$unit};
      }
      .#{str-slice($boxPart, 0, 1)}-#{str-slice($side, 0, 1)}-#{$i}_5 {
        #{$boxPart}-#{$side}: #{$i}.5#{$unit};
      }
    }
  }
}

header {
  background-color: #444444;
  min-height: 56px; // Necessary for fixed navbar
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-top: env(safe-area-inset-top);
}

footer {
  background-color: #444;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 10;

  &::before {
    height: 80px;
  }

  .btn {
    height: 3.6rem;
    font-size: 1rem;
    padding: 0 1.1em;
  }
}

.btn {
  font-weight: 500;
  text-transform: uppercase;

  .icon {
    margin-right: 0.5rem;
  }
}

.fixed-top-spacer {
  margin-top: calc(56px + 1rem + env(safe-area-inset-top));
}

.footer-spacer {
  padding-bottom: 80px;
}

.icon {
  display: inline-block;

  &.item-left,
  &.item-right,
  &.item-start,
  &.item-end {
    margin-left: 0;
    margin-top: 11px;
    margin-bottom: 10px;
    flex-direction: column;
    text-align: center;

    * {
      display: flex;
      text-align: center;
      justify-content: center;
    }

    + .item-inner,
    + .item-input,
    + .item-inner,
    + .item-input {
      margin-left: 24px;
    }
  }
}

.item-left,
.item-right,
.item-start,
.item-end {
  margin: 9px 8px 9px 0;
}

.loadingSpinner {
  margin: 2rem;
  font-size: 4rem;
  display: flex;
  justify-content: center;
}

.safeAreaWrapper {
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
}

.swal2-popup .swal2-styled.swal2-confirm {
  background-color: #007bff !important;
  border-color: #007bff !important;
  box-shadow: none !important;
}

.thumbnail img {
  width: 80px;
  height: 80px;
}
