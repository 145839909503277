.ItemDetails {
  h3 {
    margin: 1rem 0;
    text-align: center;
  }

  .thumbnail {
    text-align: center;

    img {
      height: auto;
      max-height: 200px;
      width: auto;
    }
  }
}