.CartCategoriesList {
  border-bottom: 0.55px solid #c8c7cc;
  border-left: 0.55px solid #c8c7cc;
  border-right: 0.55px solid #c8c7cc;
  margin: 1rem 0;

  .CartCategory {
    .CartProduct:last-child {
      border-bottom: none;
    }
  }
}