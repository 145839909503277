.StoreDetails {
  .store-no {
    text-align: center;
  }

  .store-address-wrapper {
    padding: 5px;
    border: 0.55px solid #c8c7cc;
    border-radius: .25rem;
  }

  .store-hours {
    margin-top: 1rem;
    text-align: center;
  }

  .store-hours-wrapper {
    padding: 5px;
    border: 0.55px solid #c8c7cc;
    border-radius: .25rem;
    margin-bottom: 15px;
  }

  footer {
    .btn {
      border-radius: 0;
    }
  }
}