.Stores {
  .nav {
    margin: 1rem 0;

    .nav-link {
      background: transparent;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 0;
      color: #00abe8;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: uppercase;

      &.active {
        border-color: #00abe8;
        background: transparent;
        color: #00abe8;
      }
    }
  }
}
